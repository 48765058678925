<template>
  <div class="governance privacypolicy" id="privacypolicy">
    <div class="ui grid container">
      <div class="intro-content">
        <h3>TayoCash Privacy Policy</h3>
        <div class="paragraph">
          <h4>1. Our Data Privacy Policy</h4>
          <p>1.1 TayoCash is committed to making sure that your privacy is protected and secure everytime you access the TayoCash App or website or use its features and services.</p>

          <p>1.2 Whenever you access or use the Tayocash app or any of its features and services, you are allowing TayoCash, its subsidiaries, affiliates, partners, and  subcontractors, to access your personal information as described in this Privacy Policy.</p>

          <p>1.3 As a TayoCash Member, you have the right to know how we collect, use, process and protect your personal data in accordance with the Data Privacy Act of 2012 (Republic Act No.  10173) and other applicable laws and regulations. We, at TayoCash, believe that by keeping you informed, you can safeguard your data and assert your privacy rights better.</p>

          <p>1.4 This policy will be updated to reflect any change or modification related to Data Privacy laws and requirements, and to keep abreast with technological advancements, protocols, and new industry practices. We encourage you to check this document from time to time  so that you can be updated accordingly.</p>
        </div>
        <div class="paragraph">
          <h4>2. What kind of information do we collect</h4>

          <p>We collect personal information and sensitive personal information, as defined under the Data Privacy Act, when you access or use the TayoCash App and its services. These information may include the following:</p>

          <p>2.1 Your name, date and place of birth, gender, marital status, age, nationality, residence, office and email addresses, personal and business mobile numbers, specimen signature or biometrics, and your photo;</p>

          <p>2.2 Information gathered from government records such as, but not limited to, information from your Philsys ID/ePhilsys ID, passport, SSS, Philheath, BIR, SEC, DTI, NBI, courts, and other government agencies;</p>

          <p>2.3 Your employment and/or business records and information;</p>

          <p>2.4 Your financial information, such as source of income and/or funds;</p>

          <p>2.5 Your bank and credit card details;</p>

          <p>2.6 Your account activities, transaction details, movements, and interactions with our Partners, Merchants, utility companies, and the likes when you access and/or avail of our products and services;</p>

          <p>2.7 The history and frequency of your use of the TayoCash Service;</p>

          <p>2.8 The device, IP address, operating system, browser type and version, and other machine identifiers that you use when you access and/or use our products and services;</p>

          <p>2.9 Your location if you use our location-based content;</p>

          <p>2.10 All communications, including voice recordings, with you by our employees, authorized representatives, agents, and service providers such as your comments, feedback, responses to surveys, and the likes;</p>

          <p>2.11 Anonymous information and data, generated by analytics tools using cookies or similar technologies to enhance customer experience, whenever you access or use our website, online and mobile applications, and electronically communicate with us; and</p>

          <p>2.12 Such other information where required or permitted by law, or with your prior consent.</p>
        </div>

        <div class="paragraph">
          <h4>3. Why we collect these information</h4>
          <p>We collect information for various lawful and legitimate purposes such as, but not limited to, the following:</p>

          <p>3.1 To comply with the legal and regulatory requirements of the Anti-Money Laundering Act (AMLA) of 9160, as amended by Republic Act No. 10365, and the regulations of the Bangko Sentral ng Pilipinas (BSP) and other government agencies;</p>

          <p>3.2 To enable us to deliver the products and services you are availing;</p>

          <p>3.3 To improve the delivery of our products and services, thereby, enhancing your customer experience;</p>

          <p>3.4 To provide customer care services when you access and/or avail of our products and services;</p>

          <p>3.5 To monitor and maintain the quality, security, and lawful use of our products and services;</p>

          <p>3.6 To communicate security and safety advisories and other public service announcements, and comply with the requirements of public order and safety, as prescribed by law;</p>

          <p>3.7 To send commercial and promotional advertisements, loyalty and rewards offers, surveys, customer care and aftersales communications, and other broadcast push messages;</p>

          <p>3.8 To share your personal information with third-party service content providers, or other third-party services you have authorized to collect the same;</p>

          <p>3.9 To process and analyze information for statistical, research, and marketing purposes, and to generate anonymous and aggregate reports; and</p>

          <p>3.10 For such other purposes where the processing of personal information is required or permitted by law or with your consent.</p>
        </div>
        <div class="paragraph">
          <h4>4. How and where we collect these information</h4>
          <p>4.1 The information we collect are primarily from the data you voluntarily submitted to us through written or online forms, your voice recordings, or the data you inputted through the TayoCash mobile or web applications. We collect data whenever you access or use any TayoCash service such as when you register for or access your Tayocash Wallet, contact our employees, authorized representatives, agents, partners, merchants, and third-party service providers, or take part in promotional activities, loyalty programs, surveys and research, and the likes.</p>

          <p>4.2 Information may also be gathered from our subsidiaries, affiliates, partners, merchants, and third party service providers, as well as from third parties and other sources authorized by law or you to disclose such information.</p>
        </div>
        <div class="paragraph">
          <h4>5. Who we share these information with, and why</h4>
          <p>5.1 We share these personal information with the appropriate government agencies to comply with their legal and regulatory requirements; our subsidiaries, affiliates and partners, merchants, agents and third-party service providers, and other parties, under an obligation of confidentiality and with adequate safeguards for data privacy and security, as part of our business operations and the provision of our products and services.</p>

          <p>5.2 We share your information for processing, marketing, research, and other specified legitimate purposes, subject to applicable laws and regulations, with other parties upon your consent, or if personal information is publicly available.</p>

          <p>5.3 We may likewise transfer, store, and/or outsource the processing of your personal information outside the Philippines subject to appropriate security measures and requirements of the Data Privacy Act.</p>
        </div>
        <div class="paragraph">
          <h4>6. How we protect these information</h4>
          <p>6.1 In strict adherence to our Privacy Policy, which aims to protect the integrity, confidentiality and security of your personal information, we shall implement physical, technological, procedural, and organizational security measures and protocols as stated in, but will not be limited to, the points below:

            <span>6.1.1 Use of secured servers behind a firewall, encryption, and security controls;</span>

            <span>6.1.2 Restricted and limited access to your information to qualified and authorized personnel and regular training of employees to properly handle information;</span>

            <span>6.1.3 Regular and rigorous audit and testing of our infrastructure’s security protocols;</span>

            <span>6.1.4 Regular update of your information by you;</span>

            <span>6.1.5 Notification or advisory to you and the National Privacy Commission when sensitive personal information or any other information that may, under the circumstances, be used to enable identity fraud are reasonably believed to have been acquired by an unauthorized person, and that such unauthorized acquisition is likely to give rise to a real risk of serious harm;</span>

            <span>6.1.6 Imposition on third-party service providers such as third-party data storage providers and the likes, terms and conditions, which shall ensure the confidentiality of the personal information processed, prevent its use for unauthorized purposes, and generally comply with the requirements of the Data Privacy Act and other laws for processing of personal information;</span>

            <span>6.1.7 Retention of information for as long as necessary for the purpose attached to the data collection process, for the establishment, exercise or defense of legal claims, or for legitimate business purposes, or as provided by law, rules and regulations; and</span>

            <span>6.1.8 Introduction of latest security measures from time to time.</span>
          </p>
        </div>
        <div class="paragraph">
          <h4>7. Your right to access, correct, and update your personal data</h4>
          <p>7.1 You may access, correct and update your personal information in our records.</p>

          <p>7.2 We may charge a fee for processing your request for access and personal information updates. The fees will depend on the nature and complexity of requested access. The information on the processing fee shall be made available to you prior to making the request.</p>

          <p>7.3 You may reach our Customer Care Service Hotline <span class="vtxt-align">(02) 8888-TAYO (or 8888-82-96)</span> for any such requests. For concerns related to how we collect, use, share, or process your information, you may send an email to <a class="link" href="mailto:dataprivacyofficer@tayocash.com">dataprivacyofficer@tayocash.com</a></p>
        </div>

        <div class="buttons">
          <a href="/termsandconditions" class="yellow">TERMS & CONDITIONS</a>
          <a href="/amla" class="white">ANTI-MONEY LAUNDERING COMPLIANCE</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'privacypolicy'
}
</script>

<style scoped lang='scss'></style>
